.sally-rocket {
  display: inline-block;
}

.sally-rocket .rocket_wrap {
  transform: translate(0px, -68px);
}

/*=============================================
[ Inactive State Styles ]
==============================================*/

.large_window_path {
  fill: #8ea1ac;
  stroke-width: 0px !important;
}
.small_window_path {
  fill: #8ea1ac !important;
  stroke-width: 0px !important;
}
.wing_shadow {
  display: block !important;
  fill: #ff3600 !important;
}
.rocket_bottom {
  fill: #ff3600 !important;
  stroke-width: 0px !important;
}
.rocket_base {
  fill: #ffffff !important;
  stroke-width: 0px !important;
}
.rocket_shadow {
  fill: #e9edef !important;
  stroke-width: 0px !important;
}
.wing_base {
  fill: #d92e00 !important;
  stroke-width: 0px !important;
}
.fire_path {
  fill: #fc0;
}

/*=============================================
[ Animation Classes ]
==============================================*/
.fire {
  display: none;
  animation-delay: 0s;
  fill-opacity: 1;
  animation-timing-function: ease-in;
  stroke-width: 0px;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: 50% 50%;
  animation-direction: normal;
}
.rocket_wrap.flying #fireLeft {
  display: block;
  animation-delay: 0s;
  animation-name: fireLeft, fillOpacity1;
  animation-duration: 1.2s;
}
.rocket_wrap.flying #fireMiddle {
  display: block;
  animation-delay: 0s;
  animation-name: fireMiddle, fillOpacity1;
  animation-duration: 1s;
}
.rocket_wrap.flying #fireRight {
  display: block;
  animation-delay: 0s;
  animation-name: fireRight, fillOpacity1;
  animation-duration: 1.3s;
}
.rocket_wrap.flying #fireSmallLeft {
  display: block;
  animation-delay: 0s;
  animation-name: fireSmall, fillOpacity2;
  animation-duration: 1.3s;
  transform-origin: bottom;
}
.rocket_wrap.flying #fireSmallRight {
  display: block;
  animation-delay: 0.3s;
  animation-name: fireSmall, fillOpacity3;
  animation-duration: 1.6s;
  transform-origin: bottom;
}

/*=============================================
[ KeyFrame Animations ]
==============================================*/
@keyframes fireSmall {
  10% {
    transform: rotate(17deg) translateY(1px);
  }
  20% {
    transform: rotate(-13deg) translateY(2px);
  }
  30% {
    transform: rotate(21deg) translateY(3px);
  }
  40% {
    transform: rotate(-34deg) translateY(4px);
  }
  50% {
    transform: rotate(24deg) translateY(5px);
  }
  60% {
    transform: rotate(-17deg) translateY(6px);
  }
  70% {
    transform: rotate(31deg) translateY(7px);
  }
  80% {
    transform: rotate(-28deg) translateY(8px);
  }
  90% {
    transform: rotate(14deg) translateY(9px);
  }
  99% {
    transform: rotate(0deg) translateY(10px);
  }
}
@keyframes fireLeft {
  6% {
    transform: rotate(25deg);
  }
  15% {
    transform: rotate(-19deg);
  }
  25% {
    transform: rotate(25deg);
  }
  32% {
    transform: rotate(-30deg);
  }
  46% {
    transform: rotate(22deg);
  }
  54% {
    transform: rotate(-29deg);
  }
  61% {
    transform: rotate(32deg);
  }
  74% {
    transform: rotate(-9deg);
  }
  83% {
    transform: rotate(16deg);
  }
  99% {
    transform: rotate(0deg);
  }
}
@keyframes fireMiddle {
  10% {
    transform: rotate(25deg);
  }
  20% {
    transform: rotate(-25deg);
  }
  30% {
    transform: rotate(30deg);
  }
  40% {
    transform: rotate(-22deg);
  }
  50% {
    transform: rotate(29deg);
  }
  60% {
    transform: rotate(-45deg);
  }
  70% {
    transform: rotate(37deg);
  }
  80% {
    transform: rotate(-15deg);
  }
  90% {
    transform: rotate(16deg);
  }
  99% {
    transform: rotate(0deg);
  }
}
@keyframes fireRight {
  15% {
    transform: rotate(17deg);
  }
  23% {
    transform: rotate(-13deg);
  }
  37% {
    transform: rotate(21deg);
  }
  45% {
    transform: rotate(-34deg);
  }
  54% {
    transform: rotate(24deg);
  }
  67% {
    transform: rotate(-17deg);
  }
  72% {
    transform: rotate(31deg);
  }
  84% {
    transform: rotate(-28deg);
  }
  96% {
    transform: rotate(14deg);
  }
  99% {
    transform: rotate(0deg);
  }
}
@keyframes fillOpacity1 {
  0% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  50% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}
@keyframes fillOpacity2 {
  0% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  25% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}

@keyframes fillOpacity3 {
  0% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  67% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}
